import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { DialogService } from '../../app/services/dialog.service';
import { Permission } from '../auth/account.model';
import { PermissionService } from '../services/permission.service';
import { DIALOG_SERVICE } from './login.guard';

/**
 * 권한 가드
 */
export const permissionGuard = (
  ...permissionList: Permission[]
): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const permissionService = inject(PermissionService);
    const dialogServiceType = inject(DIALOG_SERVICE);
    const dialogService: DialogService = inject(dialogServiceType);

    if (permissionService.isAvail(...permissionList)) {
      return true;
    }

    return dialogService.alert('No permission').pipe(
      map(() => {
        return router.createUrlTree(['/home']);
      }),
    );
  };
};
